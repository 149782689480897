import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import PageLayout from '../layouts/Page';

export default function Template() {
  return (
    <PageLayout>
      <Helmet>
        <title>Mentions légales Thank-You Analytics</title>
      </Helmet>
      <div className="blog-post-container">
        <div className="blog-post">
          <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-24 sm:pb-16 sm:px-6 lg:px-8">
              <h1 className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Mentions légales
              </h1>
              <div className="mt-6 prose prose-green prose-lg text-gray-500 mx-auto">
                <p>
                  Conformément aux dispositions de la loi n° 2004-575 du 21 juin
                  2004 pour la confiance en l'économie numérique, il est précisé
                  aux utilisateurs du site Thank-You.io l'identité des
                  différents intervenants dans le cadre de sa réalisation et de
                  son suivi.
                </p>

                <h2>Edition du site</h2>
                <p>
                  Le présent site, accessible à l'URL thank-you.io (le « Site
                  »), est édité par : THEBRIGADE FRANCE société au capital de 51
                  000 euros, inscrite au R.C.S. de Lille dont le siège social
                  est situé au 38 Boulevard Carnot Bureau 3, 59000 Lille,
                  immatriculée sous le numéro de SIRET 883 705 667 00011,
                  représenté(e) par Damien Ousaci en tant que président dûment
                  habilité.
                </p>
                <h2>Hébergement</h2>
                <p>
                  Le Site est hébergé par la société AMAZON WEB SERVICES EMEA
                  SARL, situé au 5 rue Plaetis - L 233 Luxembourg.
                </p>
                <h2>Directeur de publication</h2>
                <p>
                  Le Directeur de la publication du Site est Damien Ousaci,
                  représentant de la société THEBRIGADE FRANCE SAS.
                </p>
                <h2>Nous contacter</h2>
                <p>
                  Par email via{' '}
                  <Link to="/contact">le formulaire de contact.</Link>
                </p>
                <p>
                  Par courrier : THEBRIGADE FRANCE, 38 Boulevard Carnot Bureau
                  3, 59000 Lille.
                </p>
                <h2>Données personnelles</h2>
                <p>
                  Le traitement de vos données à caractère personnel est régi
                  par{' '}
                  <Link to="/charte">
                    notre Charte du respect de la vie privée
                  </Link>{' '}
                  conformément au Règlement Général sur la Protection des
                  Données 2016/679 du 27 avril 2016 (« RGPD »).
                </p>
                <p>
                  THEBRIGADE FRANCE SAS a désigné un Délégué à la Protection des
                  Données (DPO) auprès de la CNIL. Les coordonnées de notre
                  Délégué à la Protection des Données sont les suivantes :
                  dpo@thank-you.io
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
